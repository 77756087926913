<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-white py-8 py-lg-5 pt-lg-7">
      <div class="container">
        <div class="header-body text-center mb-4 mb-lg-5 mb-lg-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-primary">Crea una cuenta</h1>
              <!-- <p class="text-lead text-white">
                Use these awesome forms to login or create new account in your
                project for free.
              </p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <!-- Table -->
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <div class="card bg-secondary border-0">
            <!-- <div class="card-header bg-transparent pb-5">
                <div class="text-muted text-center mt-2 mb-4">
                <small>Sign up with</small>
              </div>
             <div class="text-center">
                <a href="#" class="btn btn-neutral btn-icon mr-4">
                  <span class="btn-inner--icon"
                    ><img src="/img/icons/common/github.svg"
                  /></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"
                    ><img src="/img/icons/common/google.svg"
                  /></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div> 
            </div>-->
            <loading-overlay
                    :active.sync="loading1"
                    :is-full-page="true"
                  
                  />
            <div class="card-body px-lg-5 py-lg-3">
              <div class="text-center text-muted mb-4 font-weight-bold">
                Ingresa tus datos
              </div>
              <div class="text-muted mb-4 ">
                <small>Todos los campos que lleven ( * ) son obligatorios.</small> 
              </div>
              <form role="form" @submit.prevent="handleSubmit()">
                <loading-overlay :active.sync="loading" :is-full-page="true" />
                <base-input
                  alternative
                  class="mb-3"
                  label="Nombre completo: *"
                  placeholder="Nombre"
                  name="Name"
                  v-model="name"
                  rules="required"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.name" />
                <base-input 
                  label="País: *" 
                  alternative
                  class="mb-3">
                  <el-select
                    v-model="countries.select"
                    filterable
                    placeholder="País:"
                    @change="changeCountry()"
                  >
                    <el-option
                      v-for="option in countries.multiple"
                      :key="option.value"
                      :label="option.label"
                      :value="option"
                    >
                    </el-option>
                  </el-select>
                </base-input>
                <validation-error :errors="apiValidationErrors.country_id" />
                <base-input
                  label="Tipo de Identificación: *"
                  alternative
                  class="mb-3"
                  autocomplete="off"
                >
                  <el-select
                    v-model="identifications.select"
                    filterable
                    placeholder="Tipo de Identificación"
                    @change="changeMask()"
                  >
                    <el-option
                      v-for="option in identifications.multiple"
                      :key="option.value"
                      :label="option.label"
                      :value="option"
                    >
                    </el-option>
                  </el-select>
                </base-input>
                <validation-error :errors="apiValidationErrors.type_id" />

                <base-input
                  v-mask="mascara2"
                  label="Identificación: *"
                  alternative
                  class="mb-3"
                  placeholder="#### ##### #####"
                  name="identification"
                  v-model="identification"
                  
                  :value="identification.toUpperCase()"
                  @input="identification = $event.toUpperCase()"
                >
                </base-input>
                <validation-error
                  :errors="apiValidationErrors.identification"
                />

                <!--<base-input
                  label="Fecha de nacimiento: *"
                  alternative
                  class="mb-3"
                >
                  <flat-picker
                    placeholder="Fecha de nacimiento"
                    slot-scope="{ focus, blur }"
                    @on-open="focus"
                    @on-close="blur"
                    :config="config"
                    class="form-control datepicker"
                    v-model="birth"
                    wrap: true
                  >
                  </flat-picker>   
                                 
                </base-input>-->

                <base-input
                  label="Correo electrónico: *"
                  alternative
                  class="mb-3"
                  rules="required"
                  placeholder="Correo electrónico"
                  type="email"
                  name="Email"
                  v-model="email"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.email" />

                <base-input
                  v-mask="mascara"
                  label="Teléfono: *"
                  alternative
                  class="mb-3"
                  rules="required"
                  placeholder="Teléfono"
                  name="phone"
                  v-model="phone"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.phone" />

                <div class="vld-parent">
                  <!-- <loading-overlay
                    :active.sync="loading1"
                    :is-full-page="false"
                  /> -->

                  <base-input
                    label="Cadena de Farmacias: *"
                    alternative
                    class="mb-3">
                    <el-select
                      v-model="pharmacies.select"
                      filterable
                      placeholder="Farmacia"
                      @change="ableAddress()"
                    >
                      <el-option
                        disabled
                        selected
                        :key="'-- Seleccione una farmacia --'"
                        :label="'-- Seleccione una farmacia --'"
                        :value="0"
                      >
                      </el-option>
                      <el-option
                        v-for="option in pharmacies.multiple"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.pharmacy_id" />

                  <base-input
                    alternative
                    class="mb-3"
                    label="Nombre Farmacia: *"
                    placeholder="Nombre Farmacia"
                    name="Name"
                    v-model="namePharmacies"
                    rules="required"
                  >
                  </base-input>
                  <validation-error :errors="apiValidationErrors.namePharmacies" />

                  <base-input
                    label="Dirección: *"
                    alternative
                    class="mb-3"
                    rules="required"
                    placeholder="Dirección"
                    name="address"
                    v-model="address"
                  >
                  </base-input>
                  <validation-error :errors="apiValidationErrors.address" />

                  <!--<base-input
                    label="Representante: *"
                    alternative
                    class="mb-3"
                  >
                    <el-select
                      v-model="representative_id"
                      filterable
                      placeholder="Representante"
                    >
                      <el-option
                        v-for="option in representatives"
                        :key="option.name"
                        :label="option.name"
                        :value="option.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.representative_id"
                  />-->
                </div>

                <base-input
                  label="Contraseña: *"
                  alternative
                  class="mb-3"
                  prepend-icon="fa fa-unlock-alt"
                  placeholder="Contraseña"
                  type="password"
                  name="Password"
                  rules="required"
                  v-model="password"
                >
                </base-input>
                <password
                  class="mb-3"
                  v-model="password"
                  :strength-meter-only="true"
                  @score="showScore"
                  :showStrengthMeter="false"
                />

                <validation-error :errors="apiValidationErrors.password" />

                <base-input
                  label="Confirmar contraseña: *"
                  alternative
                  class="mb-3"
                  prepend-icon="fa fa-unlock-alt"
                  placeholder="Confirmar contraseña"
                  rules="required"
                  type="password"
                  name="Password confirmation"
                  v-model="password_confirmation"
                >
                </base-input>
                <validation-error
                  :errors="apiValidationErrors.password_confirmation"
                />

                <div class="text-muted font-italic">
                  <small
                    >seguridad de la contraseña:
                    <template v-if="scors === 0">
                      <span class="text-danger font-weight-700">
                        muy débil
                      </span>
                    </template>

                    <template v-if="scors === 1">
                      <span class="text-danger font-weight-700"> débil </span>
                    </template>

                    <template v-if="scors === 2">
                      <span class="text-warning font-weight-700"> media </span>
                    </template>

                    <template v-if="scors === 3">
                      <span class="text-success font-weight-700"> fuerte </span>
                    </template>

                    <template v-if="scors === 4">
                      <span class="text-success font-weight-700">
                        muy fuerte
                      </span>
                    </template>
                  </small>
                </div>
                <div class="row my-4">
                  <div class="col-1">
                    <base-input
                      :rules="{ required: { allowFalse: false } }"
                      name="Privacy"
                      Policy
                    >
                      <base-checkbox v-model="boolean" />
                    </base-input>
                  </div>
                  <div class="col-10">
                    <span class="text-muted"
                      >Estoy de acuerdo con los
                      <a
                        style="cursor: pointer"
                        href="/terms-conditions"
                        target="_blank"
                        >Términos y Condiciones</a
                      ></span
                    >
                  </div>
                </div>

                <div class="text-center">
                  <base-button
                    type="primary"
                    native-type="submit"
                    class="my-4"
                    :disabled="validation"
                  >Crear cuenta
                  </base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import Password from "vue-password-strength-meter";
import { Select, Option } from "element-ui";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es";
import { MASK,REGISTER_ERRORS } from "../../utilities/constants";

export default {
  components: {
    ValidationError,
    Password,
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [formMixin],
  data() {
    return {
      config: {
        wrap: true,
        altFormat: "d M, Y",
        altInput: true,
        allowInput: true,
        dateFormat: "m-d-Y",
        locale: Spanish,
      },
      name: null,
      country_id: null,
      type_id: null,
      identification: localStorage.getItem("identification") ?  localStorage.getItem("identification") : '',
      //birth: new Date(1950, 0, 1),
      birth: new Date(1980, 0, 1),
      phone: null,
      pharmacy_id: null,
      namePharmacies: null,
      address: null,
      representative_id: null,
      boolean: false,
      email: null,
      password: null,
      password_confirmation: null,
      scors: "",
      countries: {select: '', multiple: []},
      identifications: {select: localStorage.getItem("identification") ?  localStorage.getItem("identification") : '', multiple: []},
      pharmacies: {select: '', multiple: []},
      representatives: [],

      mascara: MASK[0].value,
      mascara2: MASK[0].value,
      disabled: true,
      loading1: false,
      loading: false,
      geolocation:null,
    };
  },

  methods: {
    changeCountry(){
      this.loading1=true;
      this.disabled=true;
      this.address="";
      this.pharmacy_id=null;
      this.pharmacies.select = '';
      //segun el país que se seleccione, se van a ir filtrando las farmacias y los representantes,
      this.getPharmacies(this.countries.select.value);
    },
    //si elige otro en dropdown de farmacia se habilida campo de direccion de lo contrario solo llena el campo y desabilita la edicion
    ableAddress(){
      if (this.pharmacy_id===1) {
        this.disabled=false;
        this.address="";
      } else {
        this.disabled=true;
        //let farmacia = this.pharmacies.find((e)=>e.id==this.pharmacy_id);
        //if(farmacia) this.address = farmacia.address;
      }
    },
    changeMask(){
     /*  let indice = MASK.find((e)=>e.id===parseInt(this.type_id));
      this.mascara = indice ? indice.value : null; */
      this.identification = '';
    },
    showScore(score) {
      this.scors = score;
    },
    
    formatDate(date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        "T" +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },

    //peticiones para llenar los selects necesarios
    async getPharmacies(country_id){
      await this.$store.dispatch("pharmacies/get", country_id).then(()=>{
        const pharmacie = this.$store.getters["pharmacies/get"];
        this.pharmacies = {select: '', multiple: []}
        for(let i = 0; i < pharmacie.length; i++){
          let Nuevo = {value: pharmacie[i].chain_id, label: pharmacie[i].name}
          this.pharmacies.multiple.push(Nuevo)
        }
        this.loading1 = false;
      }).catch(error => {
        this.sendError("Get pharmacies - register", error, "");
      }).finally(() =>{
        this.loading1 = false;
      });
      this.loading1 = false;
    },

    async getRepresentatives(country_id){
      await this.$store.dispatch("representatives/get", country_id).then(()=>{
        this.representatives=this.$store.getters["representatives/get"];
      }).catch(error => {
        this.sendError("Get representatives - register", error, "");
      }).finally(() =>{
        this.loading1 = false;
      });
    },
    async getCountries(){
      this.loading1 = true;
      //Aplicacion con backend realizado
      const self = this;
      await this.$store.dispatch("countries/list").then(() => {
        const paises = this.$store.getters["countries/list"];
        for(let i = 0; i < paises.length; i++){
          let Nuevo = {value: paises[i].country_id, label: paises[i].name}
          self.countries.multiple.push(Nuevo)
        }
      }).catch(error => {
        this.sendError("Get countries - register", error, "");
      }).finally(() => {
        this.loading1 = false;
      });
      this.loading1 = false; 
    },
    async getIdentifications() {
      this.loading1 = true;
      await this.$store.dispatch("identifications/list").then(() => {
        const self = this
        const identificacion = self.$store.getters["identifications/list"];
        for(let i = 0; i < identificacion.length; i++){
          let Nuevo = {value: identificacion[i].identification_id, label: identificacion[i].name}
          self.identifications.multiple.push(Nuevo)
        }
      }).catch(error => {
        this.sendError("Get type identification - register", error, "");
      }).finally(() => {
        this.loading1 = false;
      });
      this.loading1 = false;
    },
    //envio de formulario
    async handleSubmit() {
      this.loading=true;      
      if (!this.boolean) {
        this.$notify({
          type: "danger",
          message: "Debes estar de acuerdo con los Términos y Condiciones.",
        });
        this.loading=false;
        return;
      }




      if (this.password.length < 8) {
        this.$notify({
          type: "danger",
          message: "La contraseña debe tener por lo menos 8 caracteres.",
        });
        this.loading = false;
        return;
      }

      if (this.password != this.password_confirmation) {
        this.$notify({
          type: "danger",
          message: "Las contraseñas no coinciden.",
        });
        this.loading = false;
        return;
      }
        //verificacion para saber si cumple la mayoria de edad
        /*let nacimiento_verificacion = new Date();
          if (typeof (this.birth) == "string") {
            nacimiento_verificacion = this.birth.split("-");
            nacimiento_verificacion.forEach(e => e = parseInt(e));
            nacimiento_verificacion = new Date(nacimiento_verificacion[2], (nacimiento_verificacion[0] - 1), nacimiento_verificacion[1]);
          } else {
            nacimiento_verificacion = this.birth;
          }
          let fecha_maxima = new Date();
          fecha_maxima.setFullYear(fecha_maxima.getFullYear() - 18);
          if (nacimiento_verificacion.getTime() > fecha_maxima.getTime()) {
            this.$notify({
              type: "danger",
              message: "Lo sentimos, no eres mayor de edad.",
            });
            this.loading = false;
            return;
          }*/
  
  
        /* let max_date = new Date();
        max_date.setFullYear(max_date.getFullYear()-18);
        if(new Date(this.birth).getTime()>max_date.getTime()){
          this.$notify({
            type:"danger",
            message:"Lo sentimos, no eres mayor de edad",
          });
          this.loading=false;
          return;
        } */
      /*let nacimiento = new Date();

      if (typeof(this.birth) == "string") {
        nacimiento = this.birth.split("-");
        nacimiento.forEach(e => e = parseInt(e));
        nacimiento = new Date(nacimiento[2], (nacimiento[0] - 1), nacimiento[1]);
        nacimiento = this.formatDate(new Date(nacimiento));
      } else {       
        nacimiento = this.formatDate(this.birth)
      }*/

      const user = {
        name: this.name,
        country_id: this.countries.select.value,
        identification_id: this.identifications.select.value,
        identification: this.identification.split(" ").join(""),
        email: this.email,
        phone: this.phone,
        chain_id: this.pharmacies.select,
        pharmacy_name: this.namePharmacies,
        address: this.address,
        password: this.password,
        password_confirmation: this.password_confirmation,
        role: 2
      };
      
      const requestOptions = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      // Aquí se envía el registro
      try {
        await this.$store.dispatch("register", { user, requestOptions });
        localStorage.setItem("identification", this.identification.split(" ").join(""));
        this.$notify({
          type: "success",
          message: "Registrado Exitosamente.",
        });
      } catch (error) {
        console.log(error)
        console.log(error.response)
        //si hay un error envia alarma a la api de envio de alarmas (?)
        if (error.response.status >= 400) {
          this.$notify({
            type: "danger",
            message: REGISTER_ERRORS[error.response.status],
          });  
          this.loading = false;   
        } else {
          this.$notify({
            type: "danger",
            message: "¡Lo sentimos, algo salió mal! \nPor favor, inténtalo más tarde.",
          });
          this.loading = false;   
        }
        //this.setApiValidation(error.response.data.errors);
      }
    },

    async sendError(metodo, error, data) {
      await this.$store.dispatch("errors/sendError", {
          plataforma: "Tombola del éxito / Clavulin",
          metodo: metodo,
          error: JSON.stringify(error),
          data: JSON.stringify(data)
        });
    }
  },

  //Ejecutar las siguientes funciones asíncronas despúes de haber montado el componenete
  created(){
    this.getCountries();
    let self = this;
    self.countries.select = { "value": 1, "label": "Guatemala" }
    self.identifications.select = { "value": 0, "label": "" }
    this.changeCountry();
    this.getIdentifications();
  }, 
  computed:{
    validation: function () {
      return (
        !this.name ||
        !this.countries.select.label ||
        !this.identifications.select.label ||
        !this.identification ||
        !this.email ||
        !this.phone ||
        !this.pharmacies.select ||
        !this.namePharmacies ||
        !this.address ||
        !this.password ||
        !this.password_confirmation ||
        this.boolean == false
      );
    }
  }
};
</script>
<style scoped>
.card-header {
  border-bottom: none !important;
}
</style>
