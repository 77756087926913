<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-white py-8 py-lg-5 pt-lg-7">
      <div class="container">
        <div class="header-body text-center mb-4 mb-lg-5 mb-lg-7">
          <div class="row justify-content-center">
            <div class="text-center" style="margin-bottom: 5px">
              <h1 class="text-primary ">¡Registra, acumula y gana!</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent pb-5">
              <div class="text-center text-muted">
                <small>Inicia sesión con tus credenciales</small>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-1">
              <form  
                class="needs-validation"
                @submit.prevent="handleSubmit()"
                role="form"
              >
                <loading-overlay :active.sync="loading" :is-full-page="true" /> 
                <base-input
                  v-mask="mascara"
                  alternative
                  label="Identificación:"
                  class="mb-3"
                  name="Identification"
                  prepend-icon="fas fa-address-card"
                  type="text"
                  placeholder="#### ##### #####"
                  rules="required"
                  v-model="identification"
                  :value="identification.toUpperCase()"
                  @input="identification = $event.toUpperCase()"
                />
                <validation-error
                  :errors="apiValidationErrors.identification"
                />
                <base-input
                  alternative
                  label="Contraseña:"
                  class="mb-3"
                  name="Password"
                  prepend-icon="fa fa-unlock-alt"
                  type="password"
                  placeholder="Ingresa tu contraseña"
                  rules="required"
                  v-model="password"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.password" />
                <div class="text-center">
                  <base-button
                    :disabled="validation"
                    type="primary"
                    native-type="submit"
                    class="my-4"
                  >
                    Iniciar sesión
                  </base-button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <a href="/password/reset">
                <small class="text-white">¿Se te olvidó tu contraseña?</small>
              </a>
            </div>
            <div class="col-6 text-right">
              <a href="/register"
                ><small class="text-white">Crear cuenta</small></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import { LOGIN_ERRORS, MASK } from "../../utilities/constants";
export default {
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data: () => ({
    /*  email: "admin@jsonapi.com",
    password: "secret", */
    identification:  localStorage.getItem("identification") ?  localStorage.getItem("identification") : '',
    password: "",
    loading: false,
    mascara: MASK[0].value,
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
    //activa los botones hasta que algun input sea diferente de null o cadena vacia
    validation: function () {
      return (
        this.identification == null ||
        this.identification == "" ||
        this.password == null ||
        this.password == ""
      );
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      const user = {        
        identification: this.identification.split(" ").join(""),
        password: this.password,          
      };

      const requestOptions = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      try {
        await this.$store.dispatch("login", { user,   requestOptions });
        localStorage.setItem("identification", this.identification);
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: `${LOGIN_ERRORS[error.response.status]}`
        });
      }
    },
  },
};
</script>
<style scoped>
.card-header {
  border-bottom: none !important;
}
.fill-default {
    fill: #fff;
}
</style>
